import { defineStore } from 'pinia'
import axios from 'axios'
import {Storage} from '@ionic/storage';
const store = new Storage();
export const useStore = defineStore('storeId', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      platform:'',
      id: 0,
      name: 'Abrar',
      pic_url: '',
     // pic_url: '',
      user_id:'',
      r: false,
      meetup_id:'',
      matchmaker_id:'',
      paystack_public_key: 'pk_live_e58241e21a8404efd479bbbc39dc68b8409cacad' ,
      matchmaker_mini:{'name': 'loading'},
      forum_data:{   
      edit_post:{},
      view_user:{}

      },
      user_data:{
        'id':'',
        'name':'',
        'pin':'',
        'token':'',
        'unseen_notifications':'',
        'kyc':{
          'id':null,
          'name':null,
          'status':null,
          'id_type':null,
          'id_picture':null,
          'id_type':null,
          'id_number':null,
        }
       
      },
      matchmaker_data:{
          'name':'',
          'id':'',
          'payment':'',
          'type':'',
          'unseen_notifications':'',
          'status':''
      },
      meetup_data:{
        'name':'',
        'id':'',
         'paid':'',
         'type':'',


      },
      my_account:{
        'gender':'',
        'email':''

      },
      user_token: null,
      current_platform: null,
      isAdmin: true,
    }
  },

  actions: {

    changeName(){

      this.name = 'helllo';

    },

    async switchPlatform(url){
    

   
    axios.defaults.baseURL=url;
    console.log('url '+axios.defaults.baseURL);
   // console.log('token '+this.user_token);
   

  },

  async storeToken(data){
    
   
   let token = data.token;
   let id = data.id;
 
    this.user_token = token;
    this.user_id = id;
    
   await store.create();
   
   await store.set('token', token);  
   await store.set('user_id', id);      
   
   
    axios.defaults.headers.common=  {'Authorization': 'Bearer '+token};
    
  
  },

  async setMeetupData(meetup){

   //  alert('meetup --'+JSON.stringify(meetup)+'--matchmaker--'+JSON.stringify(matchmaker)+'---forum-'+JSON.stringify(user));
    await store.create();


   
  if(!(await store.get('meetup_data')))
   await store.set('meetup_data', meetup);  
  
   this.meetup_data = meetup;

  },

  
  async setUserData( user){

    //  alert('meetup --'+JSON.stringify(meetup)+'--matchmaker--'+JSON.stringify(matchmaker)+'---forum-'+JSON.stringify(user));
     await store.create();
 
     if(!(await store.get('user_data')))
    await store.set('user_data', user);  
  
  
    this.user_data = user;
 
   },

   
  async setMatchmakerData( matchmaker){

    //  alert('--matchmaker--'+JSON.stringify(matchmaker));
     await store.create();
 
    if(!(await store.get('matchmaker_data')))
    await store.set('matchmaker_data', matchmaker);      
    this.matchmaker_data = matchmaker;

 
   },

  // async initializeApp(){

  //   const store = new Storage();
  //   await store.create();
  //   let token =  await store.get('token');
  //  // this.matchmaker_data =  await store.get('matchmaker_data');
  //   // this.meetup_data =  await store.get('meetup_data');
  //   // this.user_data =  await store.get('user_data_data');
  //   axios.defaults.headers.common=  {'Authorization': 'Bearer '+token};

  // }


  },

  getters:{

    getCurrentPlatform(){
      return this.current_platform;
    },

    getAccountDetails(){
      return this.my_account;
    }
  }
})