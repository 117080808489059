import { createApp } from 'vue'
//import Forum from './Forum/App.vue'
import App from './App.vue'
//import Matchmaker from './Matchmaker/App.vue'
import axios from "axios";
import router from './router';
//import forumRouter from './Forum/router';
//import matchmakerRouter from './Matchmaker/router';
import {useStore} from '@/store/data';
import {Storage} from '@ionic/storage';
import { createPinia, setActivePinia } from 'pinia'

const pinia = createPinia();
const store = new Storage();

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia);//
 // .use(store);
 app.config.globalProperties.$pinia = useStore();
router.isReady().then(() => {

  ss();
 
});

 


// var forum_url ='https://test.arewaup.com/api';
// app.config.globalProperties.$mu_url='https://test.meetup.arewaup.com/api';
// app.config.globalProperties.$mm_url= 'https://test.matchmaker.arewaup.com/api';
// app.config.globalProperties.$forum_url= forum_url;
 
 
 



var forum_url ='https://arewaup.com/api';
app.config.globalProperties.$mu_url='https://meetup.arewaup.com/api';
app.config.globalProperties.$mm_url= 'https://matchmaker.arewaup.com/api';
app.config.globalProperties.$forum_url= forum_url;
 
// var forum_url ='http://forum.com/api';
// app.config.globalProperties.$mu_url='http://192.168.8.172/api';
// app.config.globalProperties.$mm_url= 'http://matchmaker.com/api';
// app.config.globalProperties.$forum_url= forum_url;

async function ss(){
 
 await store.create();
 let m =  await store.get('token');
 let cp =  await store.get('current_platform');

 if(cp == 'forum')
 var url = forum_url;
else{
 //var url = 'https://test.'+cp+'.arewaup.com/api';
 //var url = 'http://'+cp+'.arewaup.com/api'; //local
 var url = 'https://'+cp+'.arewaup.com/api'; //production
}
 
 axios.defaults.baseURL=url;
 app.config.globalProperties.$token=m;
 axios.defaults.headers.common = {'Authorization': 'Bearer '+m}; 
 app.mount('#app');

}







