import { createRouter, createWebHistory } from '@ionic/vue-router';

import TabsPage from '@/views/TabsPage.vue'

const routes = [
  {
    name:'home',
    path: '/home',
    component: () => import('@/views/TabsPage.vue')
  },
  {
    path: '/',
    component: () => import('@/views/TabsPage.vue')
  },
  {
    path: '/index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/forum'
      },
    
      {
        path: 'matchmaker',
        component: () => import('@/Matchmaker/views/Tabs.vue')
      },
      {
        path: 'meetup',
        component: () => import('@/Meetup/views/Tab3Page.vue')
      }
    ]
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/forum'
      },
    
      {
        path: 'matchmaker',
        component: () => import('@/Matchmaker/views/Tabs.vue')
      },
      {
        path: 'meetup',
        component: () => import('@/Meetup/views/Tab3Page.vue')
      }
    ]
  },


  //FORUM

  {
    path: '/forum',
    component: () => import('@/Forum/views/Tabs.vue'),
    redirect: '/forum/tabs'
  },



  {
    path: '/forum/tabs',
    component: () => import('@/Forum/views/Tabs.vue'),
    children:[

      {
        path: '',
        redirect: '/forum/tabs/home'
      },

      {
        path: 'home',
        component: () => import('@/Forum/views/Home.vue'),
      },
      {
        path: 'categories',
        component: () => import('@/Forum/views/Categories.vue')
      },
      {
        path: 'notifications',
        component: () => import('@/Forum/views/Notifications.vue')
      },
     
      {
        path: 'myprofile',
        component: () => import('@/Forum/views/MyProfile.vue'),
        // children: [
        
        //   {
        //     name: 'myprofile.posts',
        //     path:'/forum/tabs/myprofile/posts',
        //     component:()=>import('@/Forum/components/ExploreContainer.vue'),
        //     props:{'page':'myposts'}

        //   }



        // ],
      },
   
  

    ]
  },

  {
    name: 'myprofile.posts',
    path: '/forum/myprofile/posts',
    component: () => import('@/Forum/views/PostsTemplate.vue'),
    props:{'page':'myposts'}
  },

  {
    name: 'post.edit',
    path: '/forum/post/edit',
    component: () => import('@/Forum/views/EditPost.vue'),
  },


  
  {
    name: "postview",
    path: '/forum/post/:id',
    component: () => import('@/Forum/views/Post.vue'),
  },


  {
    name: "post.category",
    path: '/forum/categories/:category',
    component: () => import('@/Forum/views/PostsTemplate.vue'),
    props:route => ({page:'categories', category: route.params.category})
  },


    
  {
    name: "post.related",
    path: '/forum/relatedpost/:category',
    component: () => import('@/Forum/views/PostsTemplate.vue'),
    props:route =>({'page':'r.posts', category: route.params.category })
  },

  {
    name:'forum.profile',
    path: '/forum/profile/:id',
    component: () => import('@/Forum/views/Profile.vue'),
  },

  {
    name:'forum.user.profile',
    path: '/forum/user/:id/posts/',
    component: () => import('@/Forum/views/PostsTemplate.vue'),
    props:{'page':'user.posts', id: true }
    
  },

  {
    path: '/forum/createpost',
    component: () => import('@/Forum/views/CreatePost.vue'),
    
  },

  {
    path: '/forum/guidelines',
    component: () => import('@/Forum/views/Guidelines.vue'),
    
  },

  {
    path: '/forum/contact',
    component: () => import('@/Forum/views/Contact.vue'),
    
  },

 
//Matchmaker

{
  path: '/matchmaker/index',
   component: () => import('@/Matchmaker/views/Index.vue'),

},
  {
    path: '/matchmaker',
 //   component: () => import('@/Matchmaker/views/Tabs.vue'),
   redirect: '/matchmaker/tabs',
  },
   
      {
        path: '/matchmaker/tabs',
        component: () => import('@/Matchmaker/views/Tabs.vue'),
        children:[

          {
            path: '',
            redirect: '/matchmaker/tabs/home'
          },

          {
            path: 'home',
            component: () => import('@/Matchmaker/views/Home.vue'),
          },
          {
            path: 'requests',
            component: () => import('@/Matchmaker/views/Requests.vue')
          },
          {
            path: 'notifications',
            component: () => import('@/Matchmaker/views/Notifications.vue')
          },
         
          {
            path: 'chat',
            component: () => import('@/Matchmaker/views/Chat.vue')
          },
          {
            path: 'profile',
            component: () => import('@/Matchmaker/views/MyProfile.vue')
          },
      

        ]
      },
     

      {
        name:'matchmaker.newprofile',
        path: '/matchmaker/newprofile',
        component: () => import('@/Matchmaker/views/NewProfile.vue'),
      
      },

      {
        name:'matchmaker.profile',
        path: '/matchmaker/profile/:id',
        component: () => import('@/Matchmaker/views/Profile.vue'),
      
      },

      {
        name:'matchmaker.myaccount',
        path: '/matchmaker/myaccount',
        component: () => import('@/Matchmaker/views/MyAccount.vue'),
      
      },

      
      {
        name:'matchmaker.contact',
        path: '/matchmaker/home',
        component: () => import('@/Matchmaker/views/Home.vue'),
      
      },

      {
        name:'matchmaker.chat',
        path: '/matchmaker/chat/:user',
        component: () => import('@/Matchmaker/views/ChatUser.vue'),
      
      },

      
      {
     
        path: '/matchmaker/howitworks',
        component: () => import('@/Matchmaker/views/HowItWorks.vue'),
      
      },

      {
     
        path: '/matchmaker/contact',
        component: () => import('@/Matchmaker/views/Contact.vue'),
      
      },


      {
     
        path: '/matchmaker/howtopay',
        component: () => import('@/Matchmaker/views/HowToPay.vue'),
      
      },
      
      {

        path: '/matchmaker/rules',
        component: () => import('@/Matchmaker/views/Rules.vue'),
      
      },
    
//MEETUP

  {
    path: '/meetup',
    redirect: '/meetup/tabs',

  
  },

  {
    path: '/meetup/index',
     component: () => import('@/Meetup/views/Index.vue'),
  
  },


  {
    path: '/meetup/tabs/',
    component: () => import('@/Meetup/views/Tabs.vue'),
    children: [
      {
        path: '',
        redirect: '/meetup/tabs/home'
      },
      {
        name: 'meetup.home',
        path: 'home',
        component: () => import('@/Meetup/views/home.vue')
      },
      {
        path: 'notifications',
        component: () => import('@/Meetup/views/Notifications.vue')
      },
      {
        name: 'myprofile',
        path: 'profile',
        component: () => import('@/Meetup/views/MyProfile.vue')
      },

      {
        path: 'requests',
        component: () => import('@/Meetup/views/Requests.vue'),
       
      },

      {
        path: 'chat',
        component: () => import('@/Meetup/views/Chat.vue')
      },

    
      {
        path: 'myaccount',
        component: () => import('@/Meetup/views/MyAccount.vue')
      },

     
    ]
  },


 
  // {
  //   path: '/requests/received',
  //   component: () => import('@/Meetup/components/ReceivedRequest.vue')
  // },
  // {
  //   path: '/requests/sent',
  //   component: () => import('@/Meetup/components/SentRequest.vue')
  // },

      {
        name: "meetup.chat",
        path: '/meetup/chat/:user',
        component: () => import('@/Meetup/views/ChatUser.vue')
      },

        {
          name: "meetup.profile",
          path: '/meetup/profile/:id',
          component: () =>  import('@/Meetup/components/Profile.vue')
        },


  {
    path: '/meetup/howitworks',
    component: () => import('@/Meetup/views/HowItWorks.vue')
  },

  {
    path: '/meetup/howtopay',
    component: () => import('@/Meetup/views/HowToPay.vue')
  },

  {
    path: '/meetup/rules',
    component: () => import('@/Meetup/views/Rules.vue')
  },

  {
    path: '/test',
    component: () => import('@/Meetup/views/test.vue'),
  
  },


  {
    path: '/meetup/contact',
    component: () => import('@/Meetup/views/Contact.vue')
  },

  {
    path: '/meetup/newprofile',
    component: () => import('@/Meetup/components/NewProfile.vue')
  },

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
